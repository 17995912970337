<template>
  <div>
    <div class="baidunet-work-disk" />
    <!--  -->
    <div v-show="loading ||status !== 'JOB_RUNNING'" class="loading-container">
      <!--  -->
      <div v-loading="loading" style="height:100%" />
      <div class="loading-text">
        <div v-show="status === 'JOB_SUSPENDED'">您的百度网盘任务已挂起，请前往
          <span style="color:blue" @click="openTask">
            作业管理
          </span>
          恢复任务后即可正常使用。</div>
        <div v-show="status === 'JOB_PENDING'">
          <div>
            <img src="@/assets/manual/loading.gif" alt="loading" style="filter:brightness(120%)">
          </div>
          <div style="text-align:center;margin-top:20px;margin-left:60px;font-size:16px;">
            任务正在排队处理中，请耐心等待。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
  name: 'BaidunetWorkDisk',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      // 这里可以根据实际情况添加vuex的状态
      loading: state => state.baidu.loading,
      status: state => state.baidu.idState
    })
  },
  mounted() {
    // 判断ws是否断开
    this.$store.dispatch('baidu/checkJobStatus').catch((err) => {
      ElMessage.error(err)
    })
  },
  beforeUnmount() {
    this.$store.dispatch('baidu/closeWs')
  },
  methods: {
    openTask() {
      this.$store.dispatch('core/openApp', 3145878294710521)
    }
  }
}
</script>
<style lang="less" scoped>
.loading-container {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
  .loading-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
}
</style>
